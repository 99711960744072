import { Button } from 'react-bootstrap'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, withRouter } from 'react-router-dom'
import
{
	EmailShareButton,
	FacebookShareButton,
	TwitterShareButton,
} from "react-share";
import { FacebookIcon, TwitterIcon, EmailIcon } from "react-share";
import QRCode from "react-qr-code";
import useAuth from '../../../hooks/useAuth'
import { FiDownload } from 'react-icons/fi'
import { exportComponentAsPNG } from 'react-component-export-image';


const Component = React.forwardRef( ( props, ref ) =>
{
	const { projectLink } = props
	return <div ref={ ref } className="qr-poster">
		<div className="qr-box">
			<div className="logo">
				<img src="https://xarwin-assets-space.fra1.digitaloceanspaces.com/Xarwin/Xarwin-logo-dark.png" alt="" />
			</div>
			<div id="qr-code">
				<img src={ `https://quickchart.io/chart?cht=qr&chs=300x300&chl=${projectLink}` }
					alt="" />
			</div>
			<p>Scan to Experience without downloading an app</p>
		</div>
		<p><a href="https://xarwin.com">Xarwin.com</a></p>
	</div>
} );


const ViewITPage = () =>
{
	const { user, serverUrl } = useAuth();
	const [ project, setProject ] = useState( null )
	const location = useLocation()
	useEffect( () =>
	{
		const currentProject = location.currentProject
		console.log( currentProject )
		setProject( currentProject )
	}, [] )

	const ref = useRef()
	const componentRef = useRef();
	const copyToClipboardHandler = () =>
	{
		navigator.clipboard.writeText( project?.Project_Url )
		document.getElementsByClassName( "copyToClipboard" )[ 0 ].innerHTML = "Copied!"
		setTimeout( () =>
		{
			document.getElementsByClassName( "copyToClipboard" )[ 0 ].innerHTML = "Copy"
		}, 1000 )
	}

	// publish link

	return (
		<div className='project-view'>

			<div className="project-header">
				<h2 className='title'>{ project?.Project_Name }</h2>
				<div>
					<Button variant={ project?.Status?.toLowerCase() == "active" ? "success" : "danger" }>{ project?.Status.toUpperCase() }</Button >

				</div>
			</div>
			<div id="project-body">
				<p className='description'>{ project?.Description }</p>

				<div className="share d-flex flex-column flex-md-row">
					<div className="column">
						<div className='project-link'>
							<h2>Want to share?</h2>

							<div className="d-flex align-items-center">
								<input className="inputField" readOnly type="text" name="Link" value={ project?.Project_Url ? project.Project_Url : "" } id="" />
								<div className="actions">
									<button onClick={ copyToClipboardHandler }
										className="copyToClipboard">
										Copy</button>
								</div>
							</div>
						</div>
						<div className='social-share'>
							<EmailShareButton
								className='social-button'
								url={ `${project?.Project_Url}` }
								subject={ `${project?.Project_Name}` }
								body={ `${project?.Description}` }
							>
								<EmailIcon size={ 50 } round />
							</EmailShareButton>
							<FacebookShareButton
								url={ `${project?.Project_Url}` }
								quote={ `${project?.Project_Name}` }
								description={ `${project?.Description}` }
								className='social-button'
							>
								<FacebookIcon size={ 50 } round />
							</FacebookShareButton>
							<TwitterShareButton
								title={ `${project?.Project_Name}` }
								url={ `${project?.Project_Url}` }
								className='social-button'
							>
								<TwitterIcon size={ 50 } round />
							</TwitterShareButton>
						</div>
					</div>
					<div className="QR column text-center">
						<div style={ { marginTop: "20px" } }>
							<h2>Or just scan it! <FiDownload onClick={ () => exportComponentAsPNG( ref, { fileName: project?.Project_Name } ) } style={ { cursor: "pointer", color: "black" } } /></h2>
							<div id="QR-code">
								<Component projectLink={ project?.Project_Url ? project.Project_Url : "" } ref={ ref } />
							</div>
						</div>
					</div>

				</div>
				<br />
			</div>

		</div>
	)
}


export default ViewITPage


