
// dev config variables name
const local = {
	serverUrl: "http://localhost:3001",
	siteName: "xarwin-test"
};

// production variables name
const dev = {
	serverUrl: "https://api.xarwin.com",
	siteName: "xarwin-test"
};

const prod = {
	serverUrl: "https://api.xarwin.com",
	siteName: "xarwin"
};

const config = process.env.REACT_APP_STAGE === "production" ? prod : process.env.REACT_APP_STAGE === "dev" ? dev : local;


// export the default configuration
export default {
	...config,
};
