/* eslint-disable no-undef */
/* eslint-disable import/no-anonymous-default-export */

export default ( editor, opts = {} ) =>
{
	const dc = editor.DomComponents;
	const tm = editor.TraitManager;
	const defaultType = dc.getType( "default" );
	const defaultView = defaultType.view;

	dc.addType( opts.name, {
		model: {
			defaults: {
				traits: [
					{
						type: 'src',
						name: 'src',
						label: 'Link',
					},
				]
			}
		},
		isComponent: ( el ) =>
		{
			if ( el.className && el.className.includes( "qr-code" ) )
			{
				return {
					type: opts.name,
				};
			}
		},
	} );

	tm.addType( 'src', {
		noLabel: true,

		// Return a simple HTML string or an HTML element
		createInput ( { trait } )
		{

			// Create a new element container add some content
			const el = document.createElement( 'div' );
			el.innerHTML = `
		  <p>QR SRC</p>
		  <div class="qr-src-input">
			<input class="qr-src__url" placeholder="Insert URL"/>
		  </div>
		`;

			return el;
		},

		// Update the component based element changes
		onEvent ( { elInput, component } )
		{
			// `elInput` is the result HTMLElement you get from `createInput`
			const src = elInput.querySelector( '.qr-src__url' ).value;
			const qr = "https://quickchart.io/chart?cht=qr&chs=300x300&chl=".concat( src )
			component.addAttributes( { qr } );
		},

		onUpdate ( { elInput, component } )
		{
			const href = component.getAttributes().href || '';
			elInput.querySelector( '.qr-src__url' ).value = href;
		},
	} );
};
